/* stylelint-disable declaration-no-important, no-descending-specificity, selector-no-qualifying-type */
@charset "UTF-8";
@import 'modules/common/bootstrap3/bootstrap/bootstrap';
@import 'font';

// colours
$dark-blue: #104080;
$mid-blue: #0067b2;
$sky-blue: #00a3e2;
$greyed-baby-blue: #c8cfdb;
$light-mid-blue: #e6f0f8;
$pink: #e61657;
$dark-red: #db2127;
$bold-red: #ff5b4f;
$dark-grey: #333;
$mid-grey: #b2b2b2;
$text-grey: #4d4d4d;
$light-grey: #d9d9d9;
$grey-40: #666;
$grey-95: #f2f2f2;
$white: #fff;

// font sizes;
$af-h1: 3.998em;
$af-h2: 2.827em;
$af-h3: 1.999em;
$af-h4: 1.414em;
$af-h5: 0.707em;

$mt-h1: 2.441em;
$mt-h2: 1.953em;
$mt-h3: 1.563em;
$mt-h4: 1.25em;
$mt-h5: 0.8em;

// font weights
$bold: 700;
$semi-bold: 600;
$regular: 400;
$light: 300;

* {
  box-sizing: border-box;
  font-size: 1em;
  font-family: $default-font-family;
}

body {
  font-size: 16px;
  background: $dark-blue;
}

/* authentication module styling */

.authentication {
  background-color: $dark-blue;

  .alert {
    color: $pink;
    padding-left: 0;
  }

  .help-block {
    color: $pink;
  }

  .alert-error,
  .formError {
    color: $pink;
    padding: 0;
    margin: 15px 0;
  }

  .formError {
    padding: 0 15px 0 0;
  }

  h1 {
    font-weight: $semi-bold;
    font-size: $mt-h1;
    display: block;
    color: $dark-blue;
    margin-top: 0;
    padding-top: 0;
  }

  h2 {
    font-weight: $semi-bold;
    font-size: $mt-h2;
    color: $dark-blue;
    margin-bottom: 1em;
  }

  h3 {
    font-weight: $semi-bold;
    font-size: $mt-h3;
    color: $dark-blue;
  }

  h4 {
    font-weight: $semi-bold;
  }

  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class^='col-'],
    & > [class*=' col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .link {
    color: $mid-blue;
    display: inline-block;

    &:hover {
      text-decoration: none;
      color: $dark-blue;
    }
  }

  .button {
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    min-width: 115px;
    line-height: 1em;
    border: 1px solid $white;
    color: $mid-blue;
    text-align: center;
    transition: 0.2s ease-out;

    i {
      vertical-align: middle;
    }

    &:hover {
      text-decoration: none;
      color: $dark-blue;
      border-color: $dark-blue;
    }

    &.primary {
      background: $mid-blue;
      color: $white;
      font-weight: $semi-bold;
      border-color: $mid-blue;

      &:hover {
        background: $dark-blue;
        color: $white;
      }
    }

    &.secondary {
      border-style: solid;
      border-width: 1px;
      font-weight: $semi-bold;
      padding: 10px 15px;

      &:hover {
        background: $white;
        color: $dark-blue;

        i {
          color: $dark-blue;
        }
      }
    }

    & + & {
      margin-left: 10px;
    }
  }

  @mixin input-wrapper {
    padding: 0;
    border-radius: 5px;
    border: 2px solid $mid-grey;
    margin-bottom: 20px;
    background: $white;
    position: relative;

    input,
    select {
      border-radius: 5px;
      border: 0;
      padding: 15px 15px 12px 15px;
      width: 100%;
      height: 100%;

      &::placeholder {
        font-weight: $semi-bold;
        color: $mid-grey;
      }
    }

    select {
      appearance: none;
      cursor: pointer;
      outline: 0;
      box-shadow: none;
      background: none;
    }

    select::-ms-expand {
      display: none;
    }
  }

  .input-wrapper {
    @include input-wrapper;
  }

  .select-wrapper {
    @include input-wrapper;

    &::after {
      position: absolute;
      top: 7px;
      right: 10px;
      width: 32px;
      height: 100%;
      pointer-events: none;
      font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      content: '\f078';
      text-align: center;
      line-height: 2.2;
      font-weight: $semi-bold;
    }
  }

  input[type='checkbox'] {
    appearance: none;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
    background: none;
    position: relative;
    margin: 0 15px 0 0;
    margin-left: initial;
    width: 16px;
    height: 16px;
    border: 2px solid $mid-blue;
    border-radius: 5px;
    vertical-align: middle;
    float: initial;

    &:hover {
      background: $mid-blue;
    }

    &:checked {
      background: $dark-blue;
      border-color: $dark-blue;

      &::after {
        color: $white;
        font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        content: '\f00c';
        font-size: 10px;
        line-height: 1;
        position: absolute;
        left: 2px;
        top: 1px;
      }
    }
  }

  .checkbox {
    color: $dark-grey;

    input[type='checkbox'] {
      margin-left: initial;
      float: initial;
    }
  }

  /* samedi-app saved usernames dropdown */
  .twitter-typeahead {
    width: 100%;

    .typeahead {
      background-color: $white;
    }

    .typeahead:focus {
      border: 2px solid $mid-blue;
    }

    .tt-query {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .tt-hint {
      color: $mid-grey;
    }

    .tt-menu {
      width: 100%;
      margin-top: -11px;
      padding: 8px 0;
      background-color: $white;
      border: 2px solid $mid-grey;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }

    .tt-suggestion {
      padding: 3px 13px;
      font-size: 1em;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .tt-suggestion:hover {
      cursor: pointer;
      color: $white;
      background-color: $mid-blue;
    }

    .tt-suggestion.tt-cursor {
      color: $white;
      background-color: $dark-blue;
    }

    .tt-suggestion p {
      margin: 0;
    }
  }

  label {
    color: $mid-blue;
    display: block;
    font-weight: normal;
    margin-bottom: 20px;
    padding-left: 0;
  }

  .language-selector {
    top: 13px;
    right: 15px;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    border: 1px solid $white;
    color: white;

    select {
      appearance: none;
      cursor: pointer;
      outline: 0;
      box-shadow: none;
      background: none;
      padding: 10px 32px 10px 10px;
      border: none !important;
      border-radius: 5px;
      font-weight: $bold;
      color: $white;
      line-height: 1em;
      display: block;
      width: 100%;
      height: 40px;

      option {
        color: $dark-blue;
        background: $white;
        padding: 5px 0;
        line-height: 2em;
      }
    }

    select::-ms-expand {
      display: none;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 100%;
      pointer-events: none;
      font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      content: '\f078';
      text-align: center;
      line-height: 2.2;
    }

    &:hover {
      background: $white;

      select {
        color: $dark-blue;
      }
    }

    &:hover::after,
    &:focus::after {
      color: $dark-blue;
    }
  }

  .access-code {
    margin-bottom: 15px;
  }

  .blue-col {
    background: $dark-blue;

    * {
      color: $white;
    }

    .logo-holder {
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .button {
      &.secondary {
        border-color: $white;
      }
    }

    .cta {
      display: none;
    }

    .selling-points {
      padding-bottom: 40px;
      text-align: left;

      .points-title {
        margin: 0 0 $mt-h2;
        border-bottom: 2px solid $white;
      }

      .selling-point {
        margin-top: 20px;

        .point-icon {
          text-align: left;
          line-height: 1;
          font-size: $mt-h1;

          i {
            margin: 0;
          }
        }

        .point-text {
          text-align: left;

          h4 {
            margin-top: 0;
          }

          &.show-more {
            text-align: center;
          }
        }
      }
    }
  }

  .content-col {
    background: $white;
    padding: 10% 4%;
    float: right;

    h1 {
      width: 75%;
      display: block;
    }

    .title {
      margin-bottom: 30px;
    }

    .otp-hint {
      margin-bottom: 40px;
      color: $text-grey;
    }

    .otp-label {
      margin-bottom: 24px;
      font-size: $mt-h5;
      color: $text-grey;
    }

    .otp-input-wrapper {
      margin-bottom: 0;
    }

    .otp-setup-heading {
      margin-bottom: 40px;
      color: $text-grey;
      font-size: 14px;

      &__countdown {
        font-weight: 700;
      }
    }

    .otp-setup-content {
      padding: 0;

      &__section {
        margin-bottom: 32px;
        color: $grey-40;
        font-weight: 700;
        line-height: 16px;

        input {
          font-weight: normal;
        }
      }

      &__prompt {
        margin-bottom: 24px;
        font-size: 14px;
      }

      &__qrcode {
        .otp-secret-qrcode {
          width: 170px;
          height: 170px;
          margin: auto;
        }
      }

      &__secret {
        height: 32px;
        background-color: $grey-95;
        border-radius: 3px;

        .otp-secret {
          color: $text-grey;
          font-size: 17px;
          font-weight: bold;
          line-height: 32px;
          text-align: center;
        }
      }

      &__skip-button {
        background-color: transparent;
      }
    }
  }

  .options {
    .link {
      display: block;
      padding-top: 15px;
    }

    &.otp_buttons {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .sign-up {
    .content-col {
      padding: 0 0 100px;
    }

    .cta {
      display: none;
    }
  }

  .footer {
    background-color: $dark-blue;
    display: none;
    position: fixed;
    padding: 20px 0;
    bottom: 0;
    left: 15px;
    text-align: center;
    width: 42%;
  }

  .footer-links {
    list-style: none;
    margin: 0;
    padding: 0 5px;
    display: inline-block;
    font-size: $mt-h5;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }

  .mobile-footer {
    background-color: $dark-blue;
    padding: 30px 0;
    text-align: center;

    * {
      color: $white;
    }

    h3 {
      display: inline-block;
      margin-right: 30px;
      margin-top: 0;
    }

    .cta {
      border-bottom: 1px solid $white;
      padding-bottom: 30px;
      margin-bottom: 20px;
    }

    .footer-links {
      padding-left: 0;

      li {
        margin-left: 0;
        padding: 10px;
      }
    }

    .copyright {
      display: block;
      padding-top: 10px;
    }
  }

  .copyright {
    display: inline-block;
    margin: 0 15px;
    padding: 10px 0 0;
    opacity: 0.5;
    font-size: $mt-h5;
  }

  /* reset password screen */

  .reset-password {
    h1 {
      margin-bottom: 15px;
    }
  }

  .reset-password-button-container {
    text-align: left;
  }

  .reset-password-label {
    padding: 20px 0 0;
    margin: 0;
  }

  /* browser certificates screen */

  .browser-certificates {
    h1 {
      margin-bottom: 15px;
    }

    .help {
      margin: 20px 0;
    }
  }

  /* activation screen */

  .resend-success {
    p {
      margin: 2em 0;
      font-size: 1.2em;
    }
  }

  /* media queries */

  /* xs screens 480px */

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: $screen-xs-min) {
    .language-selector {
      top: 28px;
    }
  }

  /* sm screens 768px */

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: $screen-sm-min) {
    .language-selector {
      top: 38px;
    }

    .content-col {
      min-height: 10%;
      padding: 10%;

      h1 {
        width: auto;
      }
    }

    .selling-points {
      .selling-point {
        margin: 0;

        .point-icon {
          font-size: $mt-h4;
        }
      }
    }
  }

  /* medium screens 992px */

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: $screen-md-min) {
    .language-selector {
      border: 1px solid $dark-blue;
      top: 15px;

      select {
        color: $dark-blue;
      }

      &::after {
        color: $dark-blue;
      }

      &:hover {
        background: $dark-blue;

        select {
          color: $white;
        }
      }

      &:hover::after,
      &:focus::after {
        color: $white;
      }
    }

    .blue-col {
      min-height: 100vh;
      padding-top: 230px;
      overflow: hidden;
      position: fixed;

      .logo-holder {
        text-align: center;
      }

      .logo {
        width: 60%;
        height: auto;
      }

      .cta {
        display: block;
        text-align: center;

        h3 {
          margin-bottom: 1em;
        }
      }

      .footer {
        display: block;
      }

      .selling-points {
        text-align: center;
        padding-bottom: 125px;

        .points-title {
          border: none;
          padding: 0;
          margin-bottom: 0;

          h2 {
            margin-bottom: 0;
          }
        }

        .selling-point {
          margin: 40px 0 0;

          .point-icon {
            font-size: 52px;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }

    .content-col {
      min-height: 100vh;
      padding: 200px 2% 5% 9%;
    }

    .mobile-footer {
      display: none;
    }

    .accept-terms-view {
      h1 {
        margin-bottom: 15px;
      }
    }
  }

  /* large screens 1200px */

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: $screen-lg-min) {
    h1 {
      font-size: $af-h1;
      font-weight: $semi-bold;
      letter-spacing: -0.02em;
      margin-bottom: 0;
    }

    h2 {
      font-weight: $semi-bold;
      font-size: $af-h2;
    }

    h3 {
      font-weight: $semi-bold;
      font-size: $af-h3;
    }

    .content-col {
      padding: 200px 2% 100px 9%;

      &.login-view {
        padding: 5% 2% 0 9%;
        overflow-x: auto;
      }

      .title {
        margin-bottom: 40px;
      }
    }

    .blue-col {
      padding-top: 230px;
      overflow: hidden;
      position: fixed;

      .cta {
        margin-top: 50px;
        text-align: center;
      }

      .footer {
        .copyright {
          border: none;
        }
      }

      .selling-points {
        .points-title h2 {
          margin-bottom: 20px;
        }

        .selling-point {
          .point-icon {
            font-size: 64px;
          }
        }
      }
    }

    .sign-up {
      .content-col {
        padding: 20px 0 100px;
      }
    }
  }

  .accept-terms-view {
    padding: 10% 2% 0 9%;

    h1 {
      margin-bottom: 15px;
    }
  }

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-height: 680px) and (min-width: $screen-lg-min) {
    .content-col {
      h1 {
        font-size: $mt-h1;
      }

      &.login-view {
        padding: 6% 2% 5% 9%;
      }
    }

    .blue-col {
      padding-top: 95px;
    }
  }
}

/* add to home screen styling */
.addToHomeScreen {
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-blue;
  padding: 23px 16px 24px;
  border-top: 1px solid $white;
  color: $white;
}

.addToHomeScreenText {
  margin: 0 0 16px;
}

#addToHomeScreenButton {
  color: $white;
  border-color: $white;
  background-color: transparent;
  float: right;
}

.login_form {
  padding-bottom: 16px;
}

.needs-app-update {
  &__headline {
    &-title {
      .authentication .content-col & {
        display: flex;
        align-items: center;
        margin-left: 16px;
        font-size: 56px;
      }
    }

    &-alert-icon {
      min-width: 64px;
      height: 56px;
      position: relative;
      margin-right: 16px;

      &::after {
        position: absolute;
        top: -8px;
        font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        content: '\f071';
        font-size: 56px;
        color: $dark-red;
      }
    }
  }

  &__content {
    max-width: 550px;
    margin-left: 16px;

    &-text {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 32px;
      color: $text-grey;
    }
  }

  &__download-button {
    width: 100%;
    height: 50px;
    margin: 40px 0 32px;
    font-weight: bold;

    &-icon {
      position: relative;

      &::after {
        position: absolute;
        font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        content: '\f019';
        font-weight: $light;
      }
    }

    &-text {
      margin-left: 16px;
      padding-left: 16px;
    }

    .authentication & {
      background-color: $mid-blue;
      color: $white;

      &:hover {
        background-color: $sky-blue;
        color: $white;
        border-color: inherit;
      }
    }
  }

  &__links {
    display: flex;
    font-size: 14px;
    line-height: 20px;

    &-text {
      margin: 0 8px;
    }

    &-separator {
      margin: 0 8px;
      color: $mid-blue;
    }

    &-information-icon {
      width: 16px;
      position: relative;

      &::after {
        position: absolute;
        height: 16px;
        width: 16px;
        font-family: 'Font Awesome 6 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        content: '\f05a';
        font-weight: $light;
        color: $mid-blue;
      }
    }

    &-icon {
      width: 20px;
      flex-basis: 0;
      float: left;
      margin-top: -3px;
    }
  }

  &__button {
    &:hover {
      text-decoration: none;
    }
  }
}

.unsupported-browser-wrapper,
.unsupported-samedi-app-wrapper {
  margin-bottom: 24px;
}

.alert-box-wrapper {
  margin-bottom: 24px;
}

.alert-box {
  display: flex;
  padding: 18px 16px;
  border-radius: 3px;
  background-color: $light-mid-blue;
  color: $mid-blue;
  border: 1px solid $greyed-baby-blue;
  line-height: 24px;
  font-size: 15px;

  &__icon-wrapper {
    font-size: 32px;
    margin-top: 8px;
    margin-right: 12px;
  }

  &__title {
    font-weight: 700;
  }

  &__content {
    color: $grey-40;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__button {
    margin: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    color: $white;
    background: $mid-blue;
    border: 1px solid $mid-blue;
    border-radius: 5px;

    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
    white-space: nowrap;
    transition: 0.2s ease-out;

    &:hover {
      background: $dark-blue
    }

    &:active {
      color: #fff;
      background-color: $mid-blue;
    }
  }
}

.unsupported-browser,
.unsupported-samedi-app {
  display: flex;
  margin-bottom: 24px;
  background-color: $bold-red;
  color: $white;
  padding: 24px;
  border-radius: 3px;

  &__icon-wrapper {
    font-size: 21px;
    margin-right: 8px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
  }

  &__content {
    padding: 8px 0;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (min-width: $screen-sm-min) {
  .alert-box,
  .unsupported-browser,
  .unsupported-samedi-app {
    width: calc(100% * 2 / 3);
  }

}

.login-autocomplete-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 3;
}

.login-autocomplete {
  list-style: none;
  padding: 10px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background: $sky-blue;
    color: $white;
  }
}

.login-autocomplete-highlight {
  background: $pink;
  color: $white;
}

.login-autocomplete-selected {
  background: $mid-blue;
  color: $white;
}
